<template>
  <div>
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2"
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.transactionScores") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-date-picker
            size="mini"
            v-model="filterForm.start_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.start_date')"
          ></el-date-picker>
          <el-date-picker
            size="mini"
            v-model="filterForm.end_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.end_date')"
          ></el-date-picker>

          <el-select
            filterable
            clearable
            :placeholder="$t('message.owner_branch')"
            size="mini"
            v-model="filterForm.owner_branch_id"
            remote
          >
            <el-option
              v-for="item in owner_branches"
              :key="item.name + item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset"
      ></div>
    </div>
    <div style="float: right">
      <el-button @click="printExcel" size="mini">
        <i class="el-icon-document-delete"></i> Excel
      </el-button>
    </div>
    <div class="filter-wrap" v-loading.body="loadingData">
      <button
        @click="statisticFilter(item, index)"
        class="filter-wrap__btn"
        :class="{ active: index === activeFilterIndex }"
        v-for="(item, index) in score.others"
        :key="index"
      >
        {{ $t(`message.${index}`) }}
      </button>
    </div>

    <div class="card-box" v-loading.body="loadingData">
      <ul class="list" v-for="(item, index) in activeItem" :key="index">
        <el-divider class="list__title" content-position="left">
          <span
            class="list__item-icon"
            :style="{
              background: iconBackground(index),
            }"
          >
            <i :class="iconIcon(index)"></i>
          </span>
          <span class="list-box-text" v-if="index == 'payment'">{{
            index == "payment" ? $t("message.payment") : ""
          }}</span>
          <span class="list-box-text" v-if="index == 'costs'">{{
            index == "costs" ? $t("message.expences") : ""
          }}</span>
          <span class="list-box-text" v-if="index == 'refund'">{{
            index == "refund" ? $t("message.refund") : ""
          }}</span>
          <span class="list-box-text" v-if="index == 'saldo'">{{
            index == "saldo" ? $t("message.saldo") : ""
          }}</span>
        </el-divider>
        <div class="list__item-wrap">
          <div
            v-for="(child, i) in item"
            :key="i"
            style="padding: 2px; border-radius: 10px; background: #e5e7eb"
          >
            <li class="list__item">
              <!-- <span
                class="list__item-icon"
                :style="{
                  background: iconBackground(index),
                }"
              >
                <i :class="iconIcon(index)"></i>
              </span> -->
              <div class="cash-wrap">
                <div class="list__item-total">
                  {{ child | formatMoney }}
                </div>
                <div>
                  {{
                    paymentTypes.find((paymentTypesitem) => {
                      return paymentTypesitem.id == i;
                    })
                      ? paymentTypes.find((paymentTypesitem) => {
                          return paymentTypesitem.id == i;
                        }).name
                      : $t("message.totaly")
                  }}
                </div>
              </div>
            </li>
          </div>
        </div>
      </ul>
      <!-- charts -->
      <div class="charts-container mt-4">
        <subscriptions-overview
          :active_filter="activeFilterIndex"
          :data="payment_chart"
        />
        <leads-by-source
          :title="$t('message.type_receive')"
          :data="payment_dialog[activeFilterIndex]"
        />
        <div class="chart-wrap money-chart">
          <Statistic
            v-if="paymentStatistic"
            v-bind="{
              icon: 'fa-solid fa-user-group',
              title: $t('message.sum_receive'),
              total: paymentStatistic[activeFilterIndex]
                ? parseInt(paymentStatistic[activeFilterIndex].sum)
                : 0,
              percent: paymentStatistic[activeFilterIndex]
                ? getPercent(paymentStatistic[activeFilterIndex])
                : 0,
              link: '#',
              color: '#8b5cf6',
              chart: 'violet-chart',
            }"
          />
          <Statistic
            v-if="costStatistic"
            v-bind="{
              icon: 'fa-solid fa-shekel-sign',
              title: $t('message.sum_expence'),
              total: costStatistic[activeFilterIndex]
                ? parseInt(costStatistic[activeFilterIndex].sum)
                : 0,
              percent: costStatistic[activeFilterIndex]
                ? getPercent(costStatistic[activeFilterIndex])
                : 0,
              link: '#',
              color: '#10b981',
              chart: 'green-chart',
            }"
          />
          <Statistic
            v-if="partnerDoctorStatistic"
            v-bind="{
              icon: 'fa-solid fa-user-group',
              title: $t('message.partner_bonus'),
              total: partnerDoctorStatistic[activeFilterIndex]
                ? parseInt(partnerDoctorStatistic[activeFilterIndex].sum)
                : 0,
              percent: partnerDoctorStatistic[activeFilterIndex]
                ? getPercent(partnerDoctorStatistic[activeFilterIndex])
                : 0,
              link: '#',
              color: '#8b5cf6',
              chart: 'violet-chart',
            }"
          />
          <Statistic
            v-if="partnerDoctorPaidStatistic"
            v-bind="{
              icon: 'fa-solid fa-shekel-sign',
              title: $t('message.paid_partner_bonus'),
              total: partnerDoctorPaidStatistic[activeFilterIndex]
                ? parseInt(partnerDoctorPaidStatistic[activeFilterIndex].sum)
                : 0,
              percent: partnerDoctorPaidStatistic[activeFilterIndex]
                ? getPercent(partnerDoctorPaidStatistic[activeFilterIndex])
                : 0,
              link: '#',
              color: '#10b981',
              chart: 'green-chart',
            }"
          />
        </div>

        <revenue-analytic
          :active_filter="activeFilterIndex"
          :data="cost_chart"
        />
        <leads-by-source
          :title="$t('message.type_expence')"
          :data="cost_dialog[activeFilterIndex]"
        />
      </div>
    </div>

    <!-- <ul v-for="(item, index) of score['Общее']" :key="index">
      {{ item }} - {{ index }}
      <li v-for="(el, subi) of item" :key="subi">
        {{ el }} - {{ subi }}
      </li>
    </ul>
    <pre>{{ score['Общее'] }}</pre> -->
    <!-- <pre>{{ score }}</pre> -->

    <!-- ----------------------------------------------------------- -->

    <!-- <div class="row table-sm mr-0 ml-0 p-0">
      <div
        style="text-align: center"
        class="col-12 align-items-center align-self-center text-center pr-0 mb-2"
        v-for="(index, item_score) in score"
        :key="item_score"
      >
        <el-divider content-position="left">
          {{ item_score }}
        </el-divider>

        <div
          v-for="(index_item_score, item_item_score) in index"
          :key="item_item_score"
          class="my-3"
        >
          {{ item_item_score == "payment" ? $t("message.payment") : "" }}
          {{ item_item_score == "costs" ? $t("message.expences") : "" }}
          {{ item_item_score == "refund" ? $t("message.refund") : "" }}

          <span class="align-items-center align-self-center pr-2">
            <b>
              <el-tag v-if="item_item_score == 'refund'" type="danger">
                {{ $t("message.total_amount") }}:{{
                  index_item_score.total | formatMoney
                }}
              </el-tag>
              <el-tag v-if="item_item_score == 'costs'" type="warning">
                {{ $t("message.total_amount") }}:{{
                  index_item_score.total | formatMoney
                }}
              </el-tag>
              <el-tag v-if="item_item_score == 'payment'">
                {{ $t("message.total_amount") }}:{{
                  index_item_score.total | formatMoney
                }}
              </el-tag>
            </b>
          </span>

          <span
            v-for="item in paymentTypes"
            :key="item.id"
            class="align-items-center align-self-center pr-2"
          >
            <b>
              <el-tag v-if="item_item_score == 'refund'" type="danger"
                >{{ item.name | uppercaseFirst }}:
                {{ index_item_score[item.id] | formatMoney }}
              </el-tag>
              <el-tag v-if="item_item_score == 'costs'" type="warning"
                >{{ item.name | uppercaseFirst }}:
                {{ index_item_score[item.id] | formatMoney }}
              </el-tag>
              <el-tag v-if="item_item_score == 'payment'"
                >{{ item.name | uppercaseFirst }}:
                {{ index_item_score[item.id] | formatMoney }}
              </el-tag>
            </b>
          </span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import SubscriptionsOverview from "./components/SubscriptionsOverview.vue";
import RevenueAnalytic from "./components/RevenueAnalytic.vue";
import LeadsBySource from "./components/LeadsBySource.vue";
import Statistic from "./components/Statistic.vue";
export default {
  name: "PatientServiceList",
  components: {
    SubscriptionsOverview,
    RevenueAnalytic,
    LeadsBySource,
    Statistic,
  },

  data() {
    return {
      loadTable: false,
      tabAddPayment: false,
      drawerUpdate: false,
      drawerFind: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      filterForm: {},
      today_transactions: 0,
      payment: {},
      costs: {},
      total: 0,
      cash: 0,
      terminal: 0,
      click: 0,
      activeItem: null,
      // new design
      activeFilterIndex: "daily",
      defaulFilterItem: {},
      filterOptions: [
        {
          text: "",
          value: "all",
        },
        {
          text: "",
          value: "today",
        },
        {
          text: "",
          value: "week",
        },
        {
          text: "",
          value: "month",
        },
        {
          text: "",
          value: "quarter",
        },
        {
          text: "",
          value: "half year",
        },
        {
          text: "",
          value: "year",
        },
        {
          text: "",
          value: "filter",
        },
      ],
      icon: [
        {
          name: "payment",
          color: "#2563eb",
          icon: "fa-solid fa-money-bill-1-wave",
        },
        {
          name: "costs",
          color: "#FDB66C",
          icon: "fa-solid fa-hand-holding-dollar",
        },
        {
          name: "refund",
          color: "#E03C3C",
          icon: "fa-solid fa-rotate-left",
        },
        {
          name: "saldo",
          color: "#16a34a",
          icon: "fa-solid fa-scale-balanced",
        },
      ],

      //statistic
      paymentStatistic: 0,
      costStatistic: 0,
      partnerDoctorStatistic: 0,
      partnerDoctorPaidStatistic: 0,
      loadingPayment: false,
      loadingCost: false,

      payment_dialog: [],
      cost_dialog: [],
    };
  },
  computed: {
    ...mapGetters({
      list: "transaction/reports",
      owner_branches: "ownerBranches/list",
      score: "transaction/score",
      filter: "transaction/filter",
      paymentTypes: "paymentTypes/list",
      payment_chart: "payment/chart",
      cost_chart: "costs/chart",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
  },
  async mounted() {
    this.fetchData();
    await this.loadOwnerBranch();

    this.paymentChart().finally(() => {
      this.loadingPayment = false;
    });
    this.costChart().finally(() => {
      this.loadingCost = false;
    });
    if (this.paymentTypes && this.paymentTypes.length === 0)
      await this.paymentType();
  },
  watch: {
    filterForm: {
      handler: async function (newVal, oldVal) {
        await this.fetchData();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      updateFilter: "transaction/updateFilter",
      loadOwnerBranch: "ownerBranches/index",
      excelReport: "transaction/excelReport",
      paymentType: "paymentTypes/index",
      updateList: "transaction/scoreReport",
      refreshData: "transaction/refreshData",
      paymentChart: "payment/chart",
      costChart: "costs/chart",
    }),
    async fetchData() {
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
        ...this.filterForm,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.defaulFilterItem = this.score.others[this.activeFilterIndex];
            this.statisticFilter(this.defaulFilterItem, this.activeFilterIndex);
            this.payment_dialog = res.charts.payment_dialog;
            this.cost_dialog = res.charts.cost_dialog;
            this.paymentStatistic = res.charts.payment_statistic;
            this.costStatistic = res.charts.cost_statistic;
            this.partnerDoctorStatistic = res.charts.partner_doctor_statistic;
            this.partnerDoctorPaidStatistic =
              res.charts.partner_doctor_paid_statistic;

            for (let key in this.score.others) {
              if (key === "Общее") {
                this.total = _.round(
                  parseFloat(this.score.others[key]["payment"]["total"]) -
                    this.score.others[key]["costs"]["total"],
                  2
                );
                this.payment["cash"] = parseFloat(
                  this.score.others[key]["payment"][1]
                );
                this.payment["terminal"] = parseFloat(
                  this.score.others[key]["payment"][2]
                );
                this.payment["click"] = parseFloat(
                  this.score.others[key]["payment"][3]
                );
                this.payment["total"] = parseFloat(
                  this.score.others[key]["payment"]["total"]
                );

                this.costs["cash"] = this.score.others[key]["costs"][1];
                this.costs["terminal"] = this.score.others[key]["costs"][2];
                this.costs["click"] = this.score.others[key]["costs"][3];
                this.costs["total"] = this.score.others[key]["costs"]["total"];
              }
            }
          })
          .finally(() => {
            console.log(this.loadingData);
            this.loadingData = false;
          });
      }
    },
    printExcel() {
      this.loadingButton = true;
      let data = JSON.parse(JSON.stringify(this.score.others));
      console.log(typeof data);
      this.excelReport(data)
        .then((res) => {
          this.loadingButton = false;
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ГЧП книга.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.loadingButton = false;
          err.text().then((text) => {
            let error = JSON.parse(text);
            if (error.code == 422) {
              this.$notify({
                title: this.$t("message.validation"),
                type: "error",
                position: "bottom right",
                message: error.error.message,
              });
            } else {
              this.$alert(error);
            }
          });
        });
    },
    getPercent(val) {
      if (val.percent_last == 0) return 0;
      return parseFloat((val.percent_last - 1) * 100).toFixed(2);
    },
    refresh() {
      this.fetchData();
    },
    iconBackground(type) {
      return this.icon.find((item) => {
        return item.name == type;
      }).color;
    },
    iconIcon(type) {
      return this.icon.find((item) => {
        return item.name == type;
      }).icon;
    },
    emptyModel() {
      this.empty();
    },

    // new
    statisticFilter(item, index) {
      this.activeItem = item;
      this.activeFilterIndex = index;
    },
  },
};
</script>

<style lang="scss">
.filter-wrap {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 16px;

  @media screen and (max-width: 1168px) {
    gap: 1rem;
    flex-wrap: wrap;
  }

  // filter-wrap__btn
  &__btn {
    outline: none;
    background: #fff;
    border: none;
    border-radius: 10px;
    padding: 6px 20px;
    color: #606266;
    font-weight: 600;
    font-size: 15px;
    transition: all 0.15s ease-in-out;

    &.active {
      color: #fff;
      background: #409eff;
    }

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: #fff;
      background: #66b1ff;
    }

    &:active {
      background: #3a8ee6;
    }
  }
}

.dark-mode .filter-wrap__btn.active {
  border-color: #4580eb !important;
  background-color: #4580eb !important;
}
</style>

<style lang="scss" scoped>
.card-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 32px;
  background: #fff;
  border-radius: 10px;

  @media screen and (max-width: 1300px) {
    gap: 1rem;
  }

  .list {
    list-style: none;
    margin: 0 !important;
    padding: 0;

    // list__item-wrap
    &__item-wrap {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 3rem;
      margin-top: 35px;

      @media screen and (max-width: 1300px) {
        gap: 1rem;
      }

      @media screen and (max-width: 1168px) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    // list__title
    &__title div {
      flex-shrink: 0;
      color: #2563eb;
      // font-weight: 700;
      // font-size: 18px;
      font-size: 16px;
      font-weight: 500;
    }

    &:nth-child(2) .list__title div {
      color: #fdb66c;
    }

    &:nth-child(3) .list__title div {
      color: #e03c3c;
    }

    &:nth-child(4) .list__title div {
      color: #16a34a;
    }

    // list__item
    &__item {
      padding: 12px;
      border-radius: 8px;
      border: 1px solid #ddd;
      display: flex;
      gap: 12px;
      align-items: center;
      background: #fff;

      // list__item-icon
      &-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: 10px;
        background: #3b82f6;
        color: #fff;
        font-size: 18px;
      }

      // list__item-total
      &-total {
        font-size: 18px;
        font-weight: 600;
        color: #333;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      // list__item-percent
      &-percent {
        font-size: 14px;
        font-weight: 500;
        color: #10b981;

        &.loss {
          color: #f43f5e;
        }
      }

      // list__item-subtitle
      &-subtitle {
        font-size: 14px;
        font-weight: 400;
        color: #bfbfd1;
        line-height: 10px;
      }
    }
  }
}

.charts-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
}
</style>
